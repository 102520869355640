.aboutslider {
  @media screen and (min-width: 1024px) {
    align-self: flex-start;
  }

  &-text {
    grid-column: 1 / span 12;

    @media screen and (min-width: 768px) {
    }

    @media screen and (min-width: 1024px) {
      grid-column: 1 / span 6;
    }

    @media screen and (min-width: 1440px) {
    }

    @media screen and (min-width: 1920px) {
    }
  }

  &-text-headline {
    margin-bottom: 8px;
  }
  &-text-copytext {
    margin-bottom: 24px;
  }

  &-slider {
    grid-column: 1 / span 12;
    padding: 0 32px;
    position: relative;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
    }

    @media screen and (min-width: 1024px) {
      grid-column: 7 / span 6;
      align-self: flex-start;
    }

    @media screen and (min-width: 1440px) {
    }

    @media screen and (min-width: 1920px) {
    }

    .swiper-slide {
      height: auto;
    }
  }

  &-slider-prev {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
  }
  &-slider-next {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
  }

  &-slider-slide {
    &-content {
      background-color: var(--color-green-light);
      height: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 1024px) {
        // flex-direction: row;
      }
    }

    &-top {
      display: flex;
      justify-content: center;
      &-text {
        display: none;

        @media screen and (min-width: 1024px) {
          width: 65%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 40px 40px 0 0;

          &.text-left {
            padding: 40px 0 0 40px;
          }
        }

        @media screen and (min-width: 1440px) {
          width: 55%;
        }
      }
      &-name {
        margin-bottom: 16px;
      }
      &-subline {}
    }

    &-image-wrapper {
      padding: 24px;
      height: 150px;

      @media screen and (min-width: 768px) {
        height: 250px;
      }

      @media screen and (min-width: 1024px) {
        padding: 0;
        width: 35%;
        height: 100%;
        display: flex;
        padding: 20px 20px 0 20px;
        max-height: 400px;

        &.image-right {
          order: 2;
        }
      }

      @media screen and (min-width: 1440px) {
        padding: 40px 40px 0 40px;
        width: 45%;
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media screen and (min-width: 1024px) {
        width: 100%;
        height: auto;
        // object-fit: none;
      }
    }
    &-text {
      padding: 0 18px 18px 18px;

      @media screen and (min-width: 1024px) {
        // width: 50%;
        padding: 32px 32px 32px 32px;
      }

      @media screen and (min-width: 1920px) {
        padding: 40px;
      }
    }
    &-name {
      margin-bottom: 8px;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
    &-subline {
      margin-bottom: 11px;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
    &-text {
      margin-bottom: 8px;
    }
  }

  &-button-wrapper-desktop {
    display: none;
    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 0 var(--grid-gutter);
      justify-items: stretch;

      & > * {
        grid-column: span 4;
      }
    }
  }
  &-button-wrapper-mobile {
    grid-column: span 12;
    margin-top: 24px;
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
}

.section--green-light .aboutslider-slider-slide-content {
  background-color: white;
}

.aboutslider--slider-left {
  @media screen and (min-width: 1024px) {
    .aboutslider-text {
      order: 2;
      grid-column: span 6;
    }
    .aboutslider-slider {
      order: 1;
      grid-column: 1 / span 6;
    }
  }
}