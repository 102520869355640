.description {
  &-anchor-wrapper {
    position: relative;
  }
  &-anchor {
    height: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: calc(-1 * (var(--spacing-section) + 110px));
  }

  &-left {
    grid-column: span 12;
    order: 2;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      order: 1;
    }
  }

  &-right {
    grid-column: span 12;
    order: 1;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      display: flex;
      flex-direction: column;
      order: 2;
    }
  }

  &-headline-desktop {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
      margin-bottom: 24px;
    }
  }

  &-headline-mobile {
    margin-top: 20px;
    margin-bottom: 16px;
    @media screen and (min-width: 768px) {
      margin-top: 0;
      margin-bottom: 24px;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &-cards {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 var(--grid-gutter);
    justify-items: stretch;

    @media screen and (min-width: 1024px) {
    }
  }

  &-text {
    margin-bottom: 24px;

    @media screen and (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  &-button-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 var(--grid-gutter);
    justify-items: stretch;

    & > * {
      grid-column: 1 / span 6;

      @media screen and (min-width: 768px) {
        grid-column: 2 / span 4;
      }

      @media screen and (min-width: 1024px) {
        grid-column: 1 / span 4;
      }
    }
  }

  &-card {
    background-color: white;
    margin-bottom: 16px;
    display: flex;
    grid-column: 1 / span 6;

    @media screen and (min-width: 768px) {
      flex-direction: column;
      grid-column: span 2;
      margin-bottom: 0;
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      grid-column: 1 / span 6;
      margin-bottom: 24px;
      min-height: 150px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      max-height: 200px;
      @media screen and (min-width: 768px) {
        width: 100%;
      }
      @media screen and (min-width: 1024px) {
        width: 40%;
      }
    }
    &-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    &-text-wrapper {
      width: 50%;
      padding: 22px 22px 22px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: 768px) {
        display: block;
        width: 100%;
        padding: 22px 22px 22px 22px;
        text-align: center;
      }
      @media screen and (min-width: 1024px) {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
      }
    }
    &-headline {
      margin-bottom: 9px;
    }
    &-text {}
  }
}