.twocolumns {
  &-left {
    grid-column: span 12;
    margin-bottom: 48px;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      margin-bottom: 0;
    }
  }

  &-right {
    grid-column: span 12;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &-headline {
    margin-bottom: 12px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 24px;
    }
  }

  &-text {
    margin-bottom: 24px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  &-button-wrapper {
    display: block;
    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 0 var(--grid-gutter);
      justify-items: stretch;

      & > * {
        grid-column: span 4;
      }
    }
  }
}