.footer {
  &-left {
    grid-column: span 12;
    padding: 0 30px;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      padding: 0 50px;
    }
  }

  &-right {
    grid-column: span 12;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &-logo-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
      max-width: 450px;
    }
  }

  &-logo {
    max-width: 450px;
    margin: 0 auto;
  }

  &-logo-slogan {
    color: white;
    font-size: 32px;
    font-weight: 800;
    line-height: 1;

    @media screen and (min-width: 768px) {
      font-size: 40px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 72px;
    }

    @media screen and (min-width: 1440px) {
      font-size: 72px;
    }
  }

  &-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 34px;

    @media screen and (min-width: 1024px) {
      justify-content: right;
      padding-left: 150px;
    }
  }

  &-link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-left: 21px;
    margin-right: 21px;
    margin-bottom: 24px;
    text-decoration: none;
    color: var(--color-black);
    cursor: pointer;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.75;
    }

    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  &-social-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 24px;

    @media screen and (min-width: 1024px) {
      justify-content: right;
    }
  }

  &-social-link {
    width: 32px;
    height: 32px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-left: 21px;
    margin-right: 21px;
    text-decoration: none;
    color: var(--color-black);
    cursor: pointer;
    transition: opacity 200ms;

    &:hover {
      opacity: 0.75;
    }
  }
}