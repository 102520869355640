.newsletter {
  &-left {
    grid-column: span 12;
    order: 2;

    @media screen and (min-width: 768px) {
      grid-column: span 7;
      order: 1;
    }
  }

  &-right {
    grid-column: span 12;
    margin-bottom: 16px;
    order: 1;
    @media screen and (min-width: 768px) {
      grid-column: span 5;
      margin-bottom: 0;
      order: 2;
    }
  }

  &-headline {
    margin-bottom: 8px;
    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 24px;
    }
  }
  &-description {
    margin-bottom: 24px;
    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  &-signup {
    background-color: white;
    min-height: 200px;
  }

  &-illustration-wrapper {
    background: white;
    height: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
  }
}