.button {
  color: white;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 200ms;

  &--primary {
    background-color: var(--color-green-primary);

    &:hover {
      background-color: rgba(11, 43, 36, 0.75);
    }
  }

  &--secondary {
    background-color: rgb(100, 156, 136);

    &:hover {
      background-color: rgba(100, 156, 136, 0.75);
    }
  }
}