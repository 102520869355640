.donate {
  &-content {
    grid-column: span 12;
    margin-bottom: 48px;
    @media screen and (min-width: 1024px) {
      grid-column: span 8;
      margin-bottom: 0;
    }
  }

  &-headline {
    margin-bottom: 12px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 24px;
    }
  }

  &-text {
    margin-bottom: 24px;
    @media screen and (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  &-buttons {
    // display: grid;
    // grid-template-columns: repeat(6, 1fr);
    // gap: 0 var(--grid-gutter);
    // justify-items: stretch;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // margin-bottom: 24px;

    & > * {
      grid-column: span 6;
    }

    @media screen and (min-width: 450px) {
      grid-template-columns: repeat(8, 1fr);

      & > * {
        grid-column: span 4;
      }
    }
  }

  &-button-wrapper {
    max-width: 250px;
    margin-bottom: 24px;
  }

  &-betterplace {
    @media screen and (min-width: 450px) {
      margin-right: 36px;
    }
  }

  &-paypal {
    // margin-top: 24px;
    // @media screen and (min-width: 499px) {
    //   margin-top: 0;
    // }
    &-logo {

    }

    #donate-button-container {
      width: 150px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}