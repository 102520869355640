.cookiebanner {
  position: fixed;
  width: 100%;
  bottom: 20px;
  z-index: 20;
  display: none;

  &.is-visible {
    display: block;
  }

  &-content {
    background-color: white;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.2);
    grid-column: span 12;
    padding: 32px 24px 24px 24px;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0 var(--grid-gutter);
    justify-items: stretch;

    @media screen and (min-width: 1024px) {
      grid-column: span 12;

      padding: 24px;
    }
  }

  &-text {
    grid-column: span 12;
    margin-bottom: 24px;
    align-items: center;
    display: flex;

    @media screen and (min-width: 1024px) {
      grid-column: span 8;
      margin-bottom: 0;
    }
  }
  &-buttons {
    grid-column: span 12;
    display: grid;
    grid: auto / repeat(12, 1fr);
    gap: 0 var(--grid-gutter);

    @media screen and (min-width: 1024px) {
      grid-column: span 4;
    }
  }
  &-button-wrapper {
    grid-column: span 12;
    &:first-of-type {
      margin-bottom: 12px;
    }

    @media screen and (min-width: 768px) {
      grid-column: span 6;
    }

    @media screen and (min-width: 1024px) {
      grid-column: span 12;
    }
  }
}