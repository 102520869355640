.network {
  &-left {
    grid-column: span 12;

    @media screen and (min-width: 1024px) {
      grid-column: span 7;
    }
  }

  &-right {
    grid-column: span 12;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 1024px) {
      grid-column: span 5;
      margin-bottom: 0;
    }
  }

  &-headline {
    margin-bottom: 8px;
    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 24px;
    }
  }
  &-description {
    margin-bottom: 24px;
    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  &-partners-wrapper {
    // display: grid;
    // grid-template-columns: repeat(6, 1fr);
    // gap: 16px var(--grid-gutter);
    // justify-items: stretch;
    // align-items: center;

    // @media screen and (min-width: 768px) {
    //   grid-template-columns: repeat(12, 1fr);
    // }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &-partner {
    width: 140px;
    padding: 12px;
    // grid-column: span 2;

    @media screen and (min-width: 1440px) {
      width: 180px;
    }

    // @media screen and (min-width: 768px) {
    //   grid-column: span 3;
    // }

    // @media screen and (min-width: 1024px) {
    //   grid-column: span 4;
    // }

    // @media screen and (min-width: 1440px) {
    //   grid-column: span 3;
    // }
  }

  &-known-headline {
    grid-column: span 12;
    margin-bottom: 24px;
  }

  &-known-logos {
    grid-column: span 12;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-known-logo-wrapper {
    width: 80px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-known-logo {
    filter: grayscale(1);
  }

  &-button-wrapper {
    grid-column: 1 / span 12;
    margin-top: 24px;

    @media screen and (min-width: 768px) {
      grid-column: 3 / span 8;
      margin-top: 40px;
    }

    @media screen and (min-width: 1024px) {
      grid-column: 5 / span 4;
      margin-top: 40px;
    }

    @media screen and (min-width: 1440px) {
    }

    @media screen and (min-width: 1920px) {
    }
  }
}