.placeholder {
  &-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-content {
    width: 80%;
    // height: 80%;
    // max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-logo {
    width: 100%;
    height: 100%;
    max-width: 400px;
  }
  &-text {
    margin-top: 36px;
    text-align: center;
    a {
      text-decoration: none;
      color: black;
      text-align: center;
      font-weight: 700;
      word-break: break-all;
    }
  }
}