.intro {
  &-left {
    grid-column: span 12;
    padding: 0 30px;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      padding: 0 50px;
    }
  }

  &-right {
    grid-column: span 12;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &-illustration-wrapper {}
  &-illustration {
    width: 100%;
    height: auto;
  }

  &-headline {
    margin-top: 16px;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      margin-top: 48px;
      margin-bottom: 16px;
    }

    @media screen and (min-width: 1024px) {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  &-text {
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }

    @media screen and (min-width: 1024px) {
      margin-top: 0;
      margin-bottom: 48px;
    }
  }

  &-button-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 var(--grid-gutter);
    justify-items: stretch;

    & > * {
      grid-column: 1 / span 6;

      @media screen and (min-width: 768px) {
        grid-column: 2 / span 4;
      }

      @media screen and (min-width: 1024px) {
        grid-column: 1 / span 4;
      }
    }

    @media screen and (min-width: 768px) {
    }
  }
}