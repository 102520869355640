.people {
  &-headline {
    grid-column: 1 / span 12;
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 24px;
    }
  }

  &-info {
    grid-column: 1 / span 12;
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
    }

    @media screen and (min-width: 1024px) {
      grid-column: 1 / span 8;
      margin-bottom: 48px;
    }
  }

  &-slider {
    grid-column: 1 / span 12;
    padding: 0 32px;
    position: relative;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
    }

    @media screen and (min-width: 1024px) {
    }

    @media screen and (min-width: 1440px) {
    }

    @media screen and (min-width: 1920px) {
    }

    .swiper-slide {
      height: auto;
    }
  }

  &-slider-prev {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
  }
  &-slider-next {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
  }

  &-slider-slide {
    &-content {
      background-color: var(--color-yellow);
      height: 100%;

      @media screen and (min-width: 1024px) {
        display: flex;
      }
    }

    
    &-image-wrapper {
      padding: 24px;
      height: 250px;

      @media screen and (min-width: 1024px) {
        padding: 0;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: end;

        &.image-right {
          order: 2;
        }
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media screen and (min-width: 1024px) {
        width: 100%;
        height: auto;
        // object-fit: none;
      }
    }
    &-text {
      padding: 0 16px 16px 16px;

      @media screen and (min-width: 1024px) {
        width: 50%;
        padding: 32px 32px 32px 32px;
      }

      @media screen and (min-width: 1920px) {
        padding: 40px;
      }
    }
    &-name {
      margin-bottom: 8px;
    }
    &-quote {
      margin-bottom: 8px;
    }
    &-subline {}
  }

  &-button-wrapper {
    grid-column: 1 / span 12;
    margin-top: 24px;

    @media screen and (min-width: 768px) {
      grid-column: 3 / span 8;
      margin-top: 40px;
    }

    @media screen and (min-width: 1024px) {
      grid-column: 5 / span 4;
      margin-top: 40px;
    }

    @media screen and (min-width: 1440px) {
    }

    @media screen and (min-width: 1920px) {
    }
  }

  &-video {
    text-align: center;
    grid-column: 1 / span 12;
    margin-bottom: 26px;

    iframe {
      aspect-ratio: 16/9;
      width: 700px;
      height: auto;
      max-width: 100%;
    }
  }
}