.meeting {
  &-anchor-wrapper {
    position: relative;
  }
  &-anchor {
    height: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: calc(-1 * (var(--spacing-section) + 110px));
  }
  &-left {
    grid-column: span 12;
    @media screen and (min-width: 768px) {
      grid-column: 3 / span 8;
    }

    @media screen and (min-width: 1024px) {
      grid-column: span 6;
    }
  }

  &-right {
    grid-column: span 12;
    @media screen and (min-width: 1024px) {
      grid-column: span 6;
      display: flex;
      flex-direction: column;
    }
  }

  &-headline {
    color: white;
    margin-bottom: 16px;
    grid-column: 1 / span 12;

    @media screen and (min-width: 768px) {
      text-align: center;
      margin-bottom: 24px;
    }
  }

  &-cards {
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0 var(--grid-gutter);
      justify-items: stretch;
      margin-bottom: 40px;
    }

    @media screen and (min-width: 1024px) {
      display: block;
      margin-bottom: 0;
    }
  }

  &-card {
    background-color: white;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      flex-direction: column;
      grid-column: span 2;
      height: 100%;
      margin-bottom: 24px;
    }

    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: row;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &-image-wrapper {
      width: 50%;

      @media screen and (min-width: 768px) {
        width: 100%;
        height: 150px;
      }

      @media screen and (min-width: 1024px) {
        width: 50%;
        height: 200px;
      }
    }

    &-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    &-text {
      width: 50%;
      padding-left: 12px;

      @media screen and (min-width: 768px) {
        width: 100%;
        padding: 24px;
        text-align: center;
      }


      @media screen and (min-width: 1024px) {
        width: 50%;
        padding-left: 0;
        text-align: left;
      }
    }
    &-headline {
      margin-bottom: 9px;
    }
    &-description {}
  }

  &-date-picker {
    position: relative;
    // min-height: 620px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 var(--grid-gutter);
    justify-items: stretch;
    align-items: center;

    @media screen and (min-width: 1024px) {
      background-color: white;
    }

    &-button {
      grid-column: auto / span 6;
      position: relative;

      @media screen and (min-width: 768px) {
        grid-column: 2 / span 4;
      }
    }

    &-background-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: none;
      @media screen and (min-width: 1024px) {
        display: block;
      }
    }
  }
}